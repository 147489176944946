<template>
  <div id="openAccount">
    <div class="content-box">
      <div class="inner">
        <div class="liveaccount-box">
          <div class="form-box" v-if="!success">
            <div class="left">
              <el-steps direction="vertical">
                <el-step icon="red"></el-step>
                <el-step icon="blue"></el-step>
                <el-step icon="blue"></el-step>
                <el-step icon="red"></el-step>
              </el-steps>
            </div>
            <div class="right">
              <h3>{{ $t('menu.openAdditAcc') }}</h3>
              <div class="box box-platform">
                <div class="box-inner">
                  <ul>
                    <li
                      v-for="(item, index) in $config.openLiveAccount.choosePlatform(regulator)"
                      :key="index"
                      @click="platformClick(index, item)"
                      :class="{ active: activePlatform == index }"
                      :data-testid="item.value"
                    >
                      <div class="img" :class="item.value"></div>
                      <span>Meta Trader {{ item.label }}</span>
                    </li>
                  </ul>
                  <span class="error" v-if="!platformFlag && verification">{{ $t('openAdditAcc.platErr') }}</span>
                </div>
              </div>
              <div class="box box-type">
                <div class="box-inner">
                  <p class="title">{{ $t('openAdditAcc.chooseAccType') }}</p>
                  <ul v-if="platform == 'mt4'">
                    <li
                      v-for="(item, index) in showMT4LiveType"
                      :key="index"
                      @click="typeClick(index, item)"
                      :class="{ active: activeType == index }"
                      :data-testid="item.value"
                    >
                      <div class="img" :class="item.value"></div>
                      <span>{{ item.label }}</span>
                    </li>
                  </ul>
                  <ul v-if="platform == 'mt5'">
                    <li
                      v-for="(item, index) in showMT5LiveType"
                      :key="index"
                      @click="typeClick(index, item)"
                      :class="{ active: activeType == index }"
                      :data-testid="item.value"
                    >
                      <div class="img" :class="item.value"></div>
                      <span>{{ item.label }}</span>
                    </li>
                  </ul>
                  <span class="error" v-if="!accountTypeFlag && verification">{{ $t('openAdditAcc.accTypeErr') }}</span>
                </div>
              </div>
              <div class="box box-currency">
                <div class="box-inner">
                  <p class="title">{{ $t('openAdditAcc.chooseCurrency') }}</p>
                  <ul>
                    <li
                      v-for="(item, index) in $config.openLiveAccount.chooseCurrency(regulator, accountType)"
                      :key="index"
                      @click="currencyClick(item)"
                      :class="{ active: activeCurrency == item.value }"
                      :data-testid="item.currency"
                    >
                      <div class="shadow-inner">
                        <div class="left"></div>
                        <div class="right">
                          <img :src="item.img" alt />
                          <p v-html="item.value"></p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <span class="error" v-if="!currencyFlag && verification">{{ $t('openAdditAcc.currError') }}</span>
                </div>
              </div>
              <div class="box box-notes">
                <div class="box-inner">
                  <p class="title">{{ $t('openAdditAcc.notes') }}</p>
                  <el-input v-model="notes" placeholder="Eg. IB/MAM/Server Location"></el-input>
                </div>
              </div>
              <div class="last-box">
                <div class="checkBox">
                  <el-checkbox name="type" v-model="checkStatus" data-testid="tncConfirm"> </el-checkbox>
                  <i18n path="openAdditAcc.byTicking" tag="div" class="tnc">
                    <template v-slot:tnc>
                      <p class="red" @click="showTNC = !showTNC" data-testid="showTNC">{{ $t('openAdditAcc.tnc') }}</p>
                    </template>
                  </i18n>
                  <span class="error" v-if="!checkStatus && verification">{{ $t('common.formValidation.tnc') }}</span>
                </div>
                <ul
                  v-show="showTNC"
                  v-html="
                    $t($config.openLiveAccount.getAgreeCondition(regulator), {
                      platform: $config.info.fullName,
                      regulatorUrl: GLOBAL_DOMAIN_WEBSITE
                    })
                  "
                ></ul>
                <div class="btn-box">
                  <el-button class="btn-blue" @click="submitClick" data-testid="submit">{{
                    $t('common.button.submit')
                  }}</el-button>
                </div>
              </div>
            </div>
          </div>
          <Result :selfImg="true" v-else>
            <template v-slot:img>
              <div class="steps">
                <img src="@/assets/images/openAccount/step01.png" alt="" />
                <div class="line"></div>
                <img src="@/assets/images/openAccount/step02.png" alt="" />
                <div class="line"></div>
                <img src="@/assets/images/openAccount/step03.png" alt="" />
              </div>
            </template>
            <p v-html="$t('openAdditAcc.succ')"></p>
          </Result>

          <DialogVersion3 :visible="pammVisible">
            <div class="dialog-body text-center">
              <p><span class="required_icon">*</span>{{ $t('openAdditAcc.pamm') }}</p>
              <div class="line"></div>
              <p class="red">*{{ $t('openAdditAcc.pammConfirm') }}</p>
              <div class="btn-box">
                <el-button class="btn-default" @click="isShowDialog = false">
                  {{ $t('common.button.confirm') }}
                </el-button>
                <el-button class="btn-blue" @click="resetType()">{{ $t('common.button.cancel') }}</el-button>
              </div>
            </div>
          </DialogVersion3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Result from '@/components/Result';
import DialogVersion3 from '@/components/dialog/v3/Dialog';
import { apiApplyAdditionalAccount } from '@/resource';

export default {
  name: 'openLiveAccount',
  components: { Result, DialogVersion3 },
  data() {
    return {
      checkStatus: false,
      activePlatform: null,
      platform: 'mt4',
      activeType: null,
      accountType: '',
      activeCurrency: null,
      currency: '',
      platformFlag: false,
      accountTypeFlag: false,
      currencyFlag: false,
      success: false,
      verification: false,
      notes: '',
      showTNC: false,
      isShowDialog: true
    };
  },
  methods: {
    platformClick(index, item) {
      this.activePlatform = index;
      this.platform = item.value;
      this.platformFlag = true;
      this.resetType();
      this.resetCurrency();
    },
    typeClick(index, item) {
      this.activeType = index;
      this.accountType = item.value;
      this.accountTypeFlag = true;

      this.isShowDialog = true;
      this.resetCurrency();
    },
    currencyClick(item) {
      this.activeCurrency = item.currency;
      this.currency = item.currency;
      this.currencyFlag = true;
    },
    submitClick() {
      this.verification = true;

      if (!this.platformFlag || !this.accountTypeFlag || !this.currencyFlag || !this.checkStatus) {
        return false;
      }

      apiApplyAdditionalAccount({
        notes: this.notes,
        accountType: this.accountType,
        tradingPlatform: this.platform,
        currency: this.currency
      }).then(resp => {
        if (resp.data.code === 0) {
          this.success = true;
        }
      });
    },
    resetType() {
      this.activeType = null;
      this.accountType = '';
      this.accountTypeFlag = false;

      this.isShowDialog = true;
    },
    resetCurrency() {
      this.activeCurrency = null;
      this.currency = '';
      this.currencyFlag = false;
    }
  },
  computed: {
    countryCode() {
      return this.$store.state.common.countryCode;
    },
    pammVisible() {
      return this.isShowDialog && this.accountType === 'PAMM';
    },
    showMT4LiveType() {
      return this.$config.openLiveAccount.chooseLiveType(this.regulator).filter(f => {
        if (f.restrictCountries) return !f.restrictCountries.includes(parseInt(this.countryCode));
        if (f.onlyCountries) return f.onlyCountries.includes(parseInt(this.countryCode));
        return f;
      });
    },
    showMT5LiveType() {
      return this.$config.openLiveAccount.mt5ChooseType(this.regulator).filter(f => {
        if (f.restrictCountries) return !f.restrictCountries.includes(parseInt(this.countryCode));
        if (f.onlyCountries) return f.onlyCountries.includes(parseInt(this.countryCode));
        return f;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/openAccount.scss';

/deep/ .el-steps {
  .el-step:nth-child(1) {
    flex-basis: 165px !important;
  }

  .el-step:nth-child(2) {
    flex-basis: 255px !important;
  }

  .el-step:nth-child(3) {
    flex-basis: calc(100% - 165px - 255px) !important;
  }
}

@media (max-width: 767px) {
  /deep/ .el-steps {
    .el-step:nth-child(1) {
      flex-basis: 170px !important;
    }

    .el-step:nth-child(2) {
      flex-basis: 470px !important;
    }

    .el-step:nth-child(3) {
      flex-basis: calc(100% - 170px - 470px) !important;
    }
  }
}
</style>
